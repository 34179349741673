import { mapState } from "vuex";
import "vue-material-design-icons/styles.css";
import store from "@/store/index";
import {
  breachesSearchTermsPost,
  breachesSearchPost,
  breachesSearchCountPost,
  breachesSearchDownloadPost,
} from "@/clients/tracelight";
import TextHighlight from "vue-text-highlight";
import draggable from "vuedraggable";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import { numFormatComma } from "@/utils/common";
export default {
  name: "BreachResearch",
  data: () => ({
    breachResearchSearchContent: "",
    listId: 0,
    currentPage: 1,
    disableExecuteButton: false,
    polling: null,
    pollDownload: false,
    showEmptyMsg: false,
    showErrorMsg: false,
    processedTermsCount: 0,
    showWildcardError: false,
    scrollTrue: false,
    wildcardIds: [],
    processedTerms: [],
    breachResearchItems: [],
    showBar: false,
    showDownload: true,
    closeImmediately: false,
    rebuild: false,
    countCapped: false,
    closeImmediately2: false,
    typeDialog: false,
    requestRemoveTypeFields: [],
    breachesSearchDownloadLink: null,
    savedBreachSearchTermsForPost: [],
    breachesSearchCountPostTotal: 0,
    fieldTypes: [
      { value: "city", text: "City" },
      { value: "company", text: "Company" },
      { value: "country", text: "Country" },
      { value: "date_of_birth", text: "Date of Birth" },
      { value: "email", text: "Email" },
      { value: "email_domain", text: "Email Domain" },
      { value: "first_name", text: "First Name" },
      { value: "gender", text: "Gender" },
      { value: "ip_address", text: "IP Address" },
      { value: "last_name", text: "Last Name" },
      { value: "name", text: "Name" },
      { value: "organization", text: "Organization" },
      { value: "password", text: "Password" },
      { value: "password_hash", text: "Password Hash" },
      { value: "person_designation", text: "Person Designation" },
      { value: "phone_number", text: "Phone Number" },
      { value: "postal_code", text: "Postal Code" },
      { value: "profile_url", text: "Profile URL" },
      { value: "government_id", text: "Government ID" },
      { value: "country_state", text: "State" },
      { value: "street", text: "Street" },
      { value: "username", text: "Username" },
    ],
    splitting: true,
    loadCount: false,
    menu: false,
    stopLoadingResults: false,
    showClipboard: false,
    fab: false,
    columnMenu: false,
    fieldTypeMenu: false,
    isPersistent: false,
    downloadDialog: false,
    pollNum: 0,
    wildcards: true,
    firstCall: true,
    showEdit: null,
    showChip: null,
    mainItemId: null,
    observer: null,
    showDataTypeSelect: null,
    downloadDialogTwo: false,
    errorDialogOne: false,
    errorDialogTwo: false,
    errorDialogTwoMsg: "",
    resultThresholdDialog: false,
    disableSearchButton: false,
    setRoute: null,
    sortBy: "email",
    sortOrder: "desc",
    timedOut: false,
    topHeaders: [
      { text: "", value: "termTotal" },
      { text: "Terms", value: "term" },
      { text: "Data Type", value: "type" },
      { text: "", value: "actions" },
    ],
    value: [],
    selectedHeaders: [],
    queries: ["*", "_"],
    allFieldsList: [
      { value: "email", text: "Email" },
      { value: "password", text: "Password" },
      { value: "username", text: "Username" },
      { value: "ip_address", text: "IP Address" },
      { value: "first_name", text: "First Name" },
      { value: "last_name", text: "Last Name" },
      { value: "breach_slug", text: "Breach" },
      { value: "email_domain", text: "Email Domain" },
      { value: "city", text: "City" },
      { value: "company", text: "Company" },
      { value: "country", text: "Country" },
      { value: "country_state", text: "State" },
      { value: "date_of_birth", text: "Date of Birth" },
      { value: "gender", text: "Gender" },
      { value: "name", text: "Name" },
      { value: "organization", text: "Organization" },
      { value: "password_hash", text: "Password Hash" },
      {
        value: "person_designation",
        text: "Person Designation",
      },
      { value: "phone_number", text: "Phone Number" },
      { value: "postal_code", text: "Postal Code" },
      { value: "profile_url", text: "Profile URL" },
      { value: "government_id", text: "Government ID" },
      { value: "street", text: "Street" },
    ],
    defaultList2: [
      { value: "city", text: "City" },
      { value: "company", text: "Company" },
      { value: "country", text: "Country" },
      { value: "country_state", text: "State" },
      { value: "email_domain", text: "Email Domain" },
      { value: "date_of_birth", text: "Date of Birth" },
      { value: "gender", text: "Gender" },
      { value: "name", text: "Name" },
      { value: "organization", text: "Organization" },
      { value: "password_hash", text: "Password Hash" },
      {
        value: "person_designation",
        text: "Person Designation",
      },
      { value: "phone_number", text: "Phone Number" },
      { value: "postal_code", text: "Postal Code" },
      { value: "profile_url", text: "Profile URL" },
      { value: "government_id", text: "Government ID" },
      { value: "street", text: "Street" },
    ],
    defaultList1: [
      { value: "email", text: "Email" },
      { value: "password", text: "Password" },
      { value: "username", text: "Username" },
      { value: "ip_address", text: "IP Address" },
      { value: "first_name", text: "First Name" },
      { value: "last_name", text: "Last Name" },
      { value: "breach_slug", text: "Breach" },
    ],
    list1: [],
    list2: [
      { value: "city", text: "City" },
      { value: "company", text: "Company" },
      { value: "country", text: "Country" },
      { value: "country_state", text: "State" },
      { value: "email_domain", text: "Email Domain" },
      { value: "date_of_birth", text: "Date of Birth" },
      { value: "gender", text: "Gender" },
      { value: "name", text: "Name" },
      { value: "organization", text: "Organization" },
      { value: "password_hash", text: "Password Hash" },
      {
        value: "person_designation",
        text: "Person Designation",
      },
      { value: "phone_number", text: "Phone Number" },
      { value: "postal_code", text: "Postal Code" },
      { value: "profile_url", text: "Profile URL" },
      { value: "government_id", text: "Government ID" },
      { value: "street", text: "Street" },
    ],
    headers: [
      { value: "email", text: "Email" },
      { value: "email_domain", text: "Email Domain" },
      { value: "password", text: "Password" },
      { value: "breach_slug", text: "Breach" },
      { value: "city", text: "City" },
      { value: "company", text: "Company" },
      { value: "country", text: "Country" },
      { value: "country_state", text: "State" },
      { value: "date_of_birth", text: "Date of Birth" },
      { value: "first_name", text: "First Name" },
      { value: "gender", text: "Gender" },
      { value: "ip_address", text: "IP Address" },
      { value: "last_name", text: "Last Name" },
      { value: "name", text: "Name" },
      { value: "organization", text: "Organization" },
      { value: "password_hash", text: "Password Hash" },
      {
        value: "person_designation",
        text: "Person Designation",
      },
      { value: "phone_number", text: "Phone Number" },
      { value: "postal_code", text: "Postal Code" },
      { value: "profile_url", text: "Profile URL" },
      { value: "government_id", text: "Government ID" },
      { value: "street", text: "Street" },
      { value: "username", text: "Username" },
    ],
  }),
  components: {
    TextHighlight,
    draggable,
    SkeletonLoader,
  },
  computed: {
    ...mapState(["confirmedUser"]),
    processedBreachSearchTerms() {
      return store.state.breachResearch.processedBreachSearchTerms;
    },
    breachSearchPostResults() {
      return store.state.breachResearch.breachSearchPostResults;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  mounted() {
    let th = this;
    let styles = {
      "overflow-x": "auto",
    };
    let obj = document.getElementsByTagName("html");
    Object.assign(obj[0].style, styles);
    setTimeout(() => {
      th.rebuild = true;
      window.addEventListener("scroll", this.onScrollEvent);
      window.addEventListener("beforeunload", (event) =>
        this.beforeUnload(event)
      );
    }, 2000);
  },
  watch: {
    /* scrollTrue: {
     *   handler: function (val, oldVal) {
     *     console.debug(val, oldVal);
     *     let th = this;
     *     if (oldVal == false && val == true) {
     *       th.observer = new IntersectionObserver(th.callback, {
     *         root: null,
     *         rootMargin: '0px',
     *         threshold: 1,
     *       });
     *       th.observer.observe(th.$refs.divAsTarget);
     *     }
     *   },
     * }, */
    splitting(val) {
      console.debug("splitting: " + val);
      /*   if (val == true) {
        this.$ga.event("Breach_Research", "Splitting_Toggle", "true", 123);
      } else {
        this.$ga.event("Breach_Research", "Splitting_Toggle", "false", 123);
      } */
    },
    wildcards(val) {
      console.debug("wildcards: " + val);
      /* if (val == true) {
        this.$ga.event("Breach_Research", "Wildcards_Toggle", "true", 123);
      } else {
        this.$ga.event("Breach_Research", "Wildcards_Toggle", "false", 123);
      } */
    },
    confirmedUser(val) {
      if (val == false) {
        if (this.polling !== null) {
          clearInterval(this.polling);
        }
        if (this.observer !== null) {
          this.observer.unobserve(this.$refs.divAsTarget);
        }
      }
    },
    list1(val) {
      this.value = val;
    },
    value(val) {
      this.selectedHeaders = val;
    },
    breachResearchSearchContent: {
      handler: function (v, ov) {
        let th = this;
        if (v == null || v == "" || v == undefined) {
          th.terms = [];
        }
      },
      deep: true,
    },
    processedBreachSearchTerms: {
      handler: function (val, oldVal) {
        console.debug(val, oldVal);
        let th = this;
        th.disableExecuteButton = false;
        let checkTermType = val.filter((i) => i.type.length == 0);
        if (val.length == 0 && oldVal.length > 1) {
          setTimeout(() => {
            th.processedTerms = oldVal;
            store.commit(
              "breachResearch/fillProcessedBreachSearchTerms",
              th.processedTerms
            );
            alert("Error, please try again.");
            return;
          }, 1000);
        }
        if (
          this.fieldTypeMenu == true &&
          this.closeImmediately == true &&
          checkTermType.length == 0
        ) {
          console.debug("check 1");
          this.removeType(
            this.requestRemoveTypeFields[0].itemId,
            this.requestRemoveTypeFields[0].type,
            this.requestRemoveTypeFields[0].index
          );
          this.fieldTypeMenu = false;
          this.closeImmediately = false;
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.pollNum > 0) {
      this.downloadDialog = true;
      this.setRoute = next;
    } else {
      this.rebuild = false;
      if (this.observer !== null) {
        this.observer.unobserve(this.$refs.divAsTarget);
      }
      clearInterval(this.polling);
      store.commit("breachResearch/clearBreachResearch", true);
      next();
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
    store.commit("breachResearch/clearBreachResearch", true);
    this.rebuild = false;
    if (this.observer !== null) {
      this.observer.unobserve(this.$refs.divAsTarget);
    }
    this.pollNum = 0;
  },
  methods: {
    restoreDefaultList() {
      const defaultList1 = [...this.defaultList1];
      const defaultList2 = [...this.defaultList2];
      this.list1 = defaultList1;
      this.list2 = defaultList2;
    },
    moveAllList() {
      const allFieldsList = [...this.allFieldsList];
      this.list1 = allFieldsList;
      this.list2 = [];
    },
    onScrollEvent() {
      this.scrollTrue = true;
    },
    copyErrorMsg(msg) {
      let tempInput = document.createElement("input");
      tempInput.value = msg;
      document.body.appendChild(tempInput);
      tempInput.select();
      this.showClipboard = true;
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    beforeUnload(event) {
      let th = this;
      if (th.pollNum > 0) {
        event.preventDefault();
        event.returnValue = "";
      } else {
        clearInterval(th.polling);
        store.commit("breachResearch/clearBreachResearch", true);
        th.rebuild = false;
        if (th.observer !== null) {
          th.observer.unobserve(th.$refs.divAsTarget);
        }
        th.pollNum = 0;
      }
    },
    checkForClose() {
      if (this.closeImmediately2 == true) {
        console.debug("check 2");
        this.removeType(
          this.requestRemoveTypeFields[0].itemId,
          this.requestRemoveTypeFields[0].type,
          this.requestRemoveTypeFields[0].index
        );
        this.fieldTypeMenu = false;
        this.closeImmediately2 = false;
      }
    },
    checkingTypes: function () {
      let checkTermType =
        store.state.breachResearch.processedBreachSearchTerms.filter(
          (i) => i.type.length == 0
        );
      if (checkTermType.length == 0) {
        console.debug(checkTermType);
      }
    },
    startDownload() {
      let th = this;

      console.debug(JSON.stringify(th.savedBreachSearchTermsForPost));
      breachesSearchDownloadPost(th.savedBreachSearchTermsForPost, {})
        .then(function (result) {
          console.debug(result.data.data);
          if (result.data.data.error) {
            th.pollData();
            th.pollDownload = true;
            th.showDownload = false;
            return;
          } else {
            th.showDownload = true;
            th.pollDownload = false;
            th.pollNum = 0;
            setTimeout(() => {
              document.getElementById("downloadPostBtn").href =
                result.data.data.url;
              let win = window.open(result.data.data.url, "_blank");
              win.focus();
            }, 500);
          }
        })
        .catch(function (error) {
          console.debug(error);
          th.errorDialogTwoMsg = error;
          th.errorDialogTwo = true;
          return;
        });
    },
    pollData() {
      let th = this;
      th.polling = setInterval(() => {
        th.pollNum++;
        console.debug("POLLING: " + th.pollNum);
        breachesSearchDownloadPost(th.savedBreachSearchTermsForPost, {
          /*deep: true,
          sort_by: th.sortBy,
          results_per_page: 20,
          page: th.currentPage,
          sort_order: th.sortOrder,*/
        })
          .then(function (result) {
            console.debug(result.data.data);
            if (result.data.data.error) {
              return;
            } else {
              th.showDownload = true;
              th.pollDownload = false;
              th.pollNum = 0;
              setTimeout(() => {
                document.getElementById("downloadPostBtn").href =
                  result.data.data.url;
                let win = window.open(result.data.data.url, "_blank");
                win.focus();
                clearInterval(th.polling);
              }, 500);
            }
          })
          .catch(function (error) {
            console.debug(error);
            th.errorDialogTwoMsg = error;
            th.errorDialogTwo = true;
            return;
          });
      }, 1000);
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    callback(entries, observer) {
      let th = this;
      console.debug(th.scrollTrue);
      console.debug(JSON.stringify(th.savedBreachSearchTermsForPost));
      let params = {};
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (th.stopLoadingResults == true) {
            console.debug(th.stopLoadingResults);
            return;
          } else {
            th.currentPage++;

            console.debug(JSON.stringify(th.savedBreachSearchTermsForPost));
            breachesSearchPost(th.savedBreachSearchTermsForPost, {
              sort_by: "email",
              results_per_page: 500,
              page: 1,
              sort_order: "asc",
            })
              .then(function (result) {
                console.debug(result.data.data);
                if (result.data.data.length == 0) {
                  th.stopLoadingResults = true;
                  console.debug("All results loaded");
                  return;
                } else {
                  if (
                    result.data.data.errorMessage !== undefined &&
                    result.data.data.errorMessage !== "undefined"
                  ) {
                    th.errorDialogTwoMsg = result.data.data.errorMessage;
                    th.$ga.exception(result.data.data.errorMessage);
                    th.errorDialogTwo = true;
                    return;
                  }
                  if (
                    result.data.data.error !== undefined &&
                    result.data.data.error !== "undefined"
                  ) {
                    th.errorDialogTwoMsg = result.data.data.error;
                    th.$ga.exception(result.data.data.error);
                    th.errorDialogTwo = true;
                    return;
                  }
                  for (let r = 0; r < result.data.data.length; r++) {
                    let eachIt = result.data.data[r];
                    store.commit(
                      "breachResearch/addMoreBreachSearchPostResults",
                      eachIt
                    );
                  }
                  if (result.data.data.length < 20) {
                    th.stopLoadingResults = true;
                    th.currentPage = 1;
                  }
                }
              })
              .catch(function (error) {
                console.debug(error);
                th.errorDialogTwoMsg = error;
                th.errorDialogTwo = true;
                return;
              });
          }
        }
      });
    },
    answerDownloadDialog(choice) {
      console.debug(choice);
      this.downloadDialog = false;
      if (choice == "leavePage") {
        console.debug("leaving");
        clearInterval(this.polling);
        this.pollNum = 0;
        this.showDownload = false;
        this.setRoute();
      } else if (choice == "waitForDownload") {
        console.debug("waiting");
        return;
      }
    },
    answerDownloadDialogTwo(choice) {
      console.debug(choice);
      this.downloadDialogTwo = false;
      if (choice == "proceed") {
        console.debug("leaving");
        clearInterval(this.polling);
        this.pollNum = 0;
        this.showDownload = false;
        this.searchNow();
      } else if (choice == "waitForDownload") {
        console.debug("waiting");
        return;
      }
    },
    addDataType(field) {
      let lowerField = field.value;
      //this.$ga.event("Breach_Research", "Add_Data_Type", field.value, 123);
      for (let z = 0; z < this.processedTerms.length; z++) {
        let eachpbst = this.processedTerms[z];
        if (eachpbst.id == this.mainItemId) {
          if (eachpbst.type.length > 0) {
            let originalType = eachpbst.type[0].value.localeCompare(lowerField);
            if (eachpbst.type.find((o) => o.value == lowerField)) {
              alert("Data Type already selected!");
              return;
            } else if (originalType == 0) {
              alert("Data Type already selected!");
              return;
            } else {
              eachpbst.type.push(field);
            }
          } else {
            eachpbst.type.push(field);
          }
        }
      }
      store.commit(
        "breachResearch/fillProcessedBreachSearchTerms",
        this.processedTerms
      );
    },
    replaceDatatype(itemId, type, index, item) {
      //this.$ga.event("Breach_Research", "Replace_Data_Type", type.value, 123);
      this.requestRemoveTypeFields = [
        { itemId: itemId, type: type, index: index, item: item },
      ];
      this.openFieldType(itemId);
      this.closeImmediately2 = true;
    },
    answerRemoveType(choice) {
      console.debug(this.requestRemoveTypeFields);
      this.typeDialog = false;
      if (choice == "deleteRow") {
        this.removeRow(this.requestRemoveTypeFields[0].item);
      }
      if (choice == "addDatatype") {
        /*  this.$ga.event(
          "Breach_Research",
          "Replace_Data_Type",
          this.requestRemoveTypeFields[0].type.value,
          123
        ); */
        this.openFieldType(this.requestRemoveTypeFields[0].itemId);
        this.closeImmediately = true;
      }
    },
    requestRemoveType(itemId, type, index, item) {
      this.requestRemoveTypeFields = [
        { itemId: itemId, type: type, index: index, item: item },
      ];
      this.typeDialog = true;
    },
    removeType(itemId, type, index) {
      //this.$ga.event("Breach_Research", "Remove_Data_Type", type.value, 123);
      for (let z = 0; z < this.processedTerms.length; z++) {
        let eachpbst = this.processedTerms[z];
        if (eachpbst.id == itemId) {
          eachpbst.type.splice(index, 1);
        }
      }
      store.commit(
        "breachResearch/fillProcessedBreachSearchTerms",
        this.processedTerms
      );
    },
    removeRow(item) {
      /*     this.$ga.event(
        "Breach_Research",
        "Remove_Row",
        JSON.stringify(item.termData) + "  " + JSON.stringify(item.type),
        123
      ); */
      this.processedTerms = this.processedTerms.filter((i) => i.id != item.id);
      store.commit(
        "breachResearch/fillProcessedBreachSearchTerms",
        this.processedTerms
      );
    },
    openFieldType(i) {
      this.mainItemId = i;
      this.fieldTypeMenu = true;
    },
    mouseOver(id) {
      this.showEdit = id;
    },
    mouseOut() {
      this.showEdit = null;
    },
    mouseOverChip(type) {
      this.showChip = type;
    },
    mouseOutChip() {
      this.showChip = null;
    },
    searchTermProcess() {
      let th = this;

      th.disableSearchButton = true;
      if (th.breachResearchSearchContent.length > 0) {
        let wildcard;
        let splitting;
        if (this.splitting == true) {
          splitting = true;
        } else {
          splitting = false;
        }
        if (this.wildcards == true) {
          wildcard = true;
        } else {
          wildcard = false;
        }
        let hasWildcardsOnly = new RegExp("^[s*_]+$").test(
          th.breachResearchSearchContent.replace(/\s/g, "")
        );
        if (this.wildcards == true && hasWildcardsOnly == true) {
          th.showWildcardError = true;
          th.disableSearchButton = false;
          return;
        } else {
          let params = {};
          let body = {
            terms: th.breachResearchSearchContent,
            split: splitting,
            wildcards: wildcard,
          };
          breachesSearchTermsPost(body, params)
            .then(function (result) {
              console.debug(result.data.data);
              if (
                result.data.data.errorMessage !== undefined &&
                result.data.data.errorMessage !== "undefined"
              ) {
                th.errorDialogTwoMsg = result.data.data.errorMessage;
                th.$ga.exception(result.data.data.errorMessage);
                th.errorDialogTwo = true;
                return;
              }
              if (
                result.data.data.error !== undefined &&
                result.data.data.error !== "undefined"
              ) {
                th.errorDialogTwoMsg = result.data.data.error;
                th.$ga.exception(result.data.data.error);
                th.errorDialogTwo = true;
                return;
              }
              th.processedTermsCount++;
              let useWildcard = result.data.data.terms.map((x) => x.wildcard);
              let showWarning;
              if (useWildcard.includes(true)) {
                showWarning = true;
              } else {
                showWarning = false;
              }
              let usetypeValue = th.fieldTypes.filter(
                (x) => x.value == result.data.data.data_type
              );
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "profile_url"
              ) {
                usetypeValue.push({
                  value: "profile_url",
                  text: "Profile URL",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "ip_address"
              ) {
                usetypeValue.push({ value: "ip_address", text: "IP Address" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "email"
              ) {
                usetypeValue.push({ value: "email", text: "Email" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "email_domain"
              ) {
                usetypeValue.push({
                  value: "email_domain",
                  text: "Email Domain",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "government_id"
              ) {
                usetypeValue.push({
                  value: "government_id",
                  text: "Government ID",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "gender"
              ) {
                usetypeValue.push({ value: "gender", text: "Gender" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "date_of_birth"
              ) {
                usetypeValue.push({
                  value: "date_of_birth",
                  text: "Date of Birth",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "city"
              ) {
                usetypeValue.push({ value: "city", text: "City" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "country"
              ) {
                usetypeValue.push({ value: "country", text: "Country" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "country_state"
              ) {
                usetypeValue.push({ value: "country_state", text: "State" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "street"
              ) {
                usetypeValue.push({ value: "street", text: "Street" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "phone_number"
              ) {
                usetypeValue.push({
                  value: "phone_number",
                  text: "Phone Number",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "postal_code"
              ) {
                usetypeValue.push({
                  value: "postal_code",
                  text: "Postal Code",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "password_hash"
              ) {
                usetypeValue.push({
                  value: "password_hash",
                  text: "Postal Code",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "company"
              ) {
                usetypeValue.push({ value: "company", text: "Company" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "organization"
              ) {
                usetypeValue.push({
                  value: "organization",
                  text: "Organization",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "username"
              ) {
                usetypeValue.push({ value: "username", text: "Username" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "first_name"
              ) {
                usetypeValue.push({ value: "first_name", text: "First Name" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "last_name"
              ) {
                usetypeValue.push({ value: "last_name", text: "Last Name" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "name"
              ) {
                usetypeValue.push({ value: "name", text: "Name" });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "person_designation"
              ) {
                usetypeValue.push({
                  value: "person_designation",
                  text: "Person Designation",
                });
              }
              if (
                usetypeValue.length == 0 &&
                result.data.data.data_type == "password"
              ) {
                usetypeValue.push({ value: "password", text: "Password" });
              }
              th.processedTerms.push({
                type: usetypeValue,
                termTotal: result.data.data.terms.length,
                termData: result.data.data.terms,
                showWarning: showWarning,
                id: th.processedTermsCount,
              });
              store.commit(
                "breachResearch/fillProcessedBreachSearchTerms",
                th.processedTerms
              );
              th.disableSearchButton = false;
              setTimeout(() => {
                let brSearchBar = document.getElementById("brSearchBar");
                brSearchBar = "";
                th.breachResearchSearchContent = "";
              }, 20);
              setTimeout(() => {
                let contentList = [];
                let brTopTable = document
                  .getElementById("brTopTable")
                  .getElementsByTagName("table")[0];
                if (brTopTable != null) {
                  let brTopTableTrs = brTopTable.getElementsByTagName("tr");
                  for (let i = 1, row; (row = brTopTableTrs[i]); i++) {
                    let eRow = brTopTableTrs[i];
                    let rowId = eRow.id.slice(-1);
                    for (let j = 0, col; (col = eRow.cells[j]); j++) {
                      let eCell = eRow.cells[1];
                      eCell.title = eCell.innerText;
                      contentList.push({
                        id: rowId,
                        termsText: eCell.innerText,
                      });
                    }
                  }
                }
                let key = "id";
                let uniqueContentList = [
                  ...new Map(
                    contentList.map((item) => [item[key], item])
                  ).values(),
                ];
              }, 1000);
            })
            .catch(function (error) {
              console.error(error);
              console.debug(error);
              th.errorDialogTwoMsg = error;
              th.errorDialogTwo = true;
              th.disableSearchButton = false;
              return;
            });
        }
      }
    },
    searchNow() {
      let th = this;
      if (th.pollNum > 0) {
        th.downloadDialogTwo = true;
        return;
      }
      th.showBar = true;
      th.showDownload = false;
      th.pollDownload = false;
      th.disableExecuteButton = true;
      th.loadCount = true;
      th.countCapped = false;
      th.scrollTrue = false;
      th.stopLoadingResults = false;
      th.showEmptyMsg = false;
      th.showErrorMsg = false;
      th.timedOut = false;

      /* store.commit('breachResearch/clearBreachResearch'); */
      store.commit("breachResearch/fillBreachSearchPostResults", []);

      const deepCopyFunction = (inObject) => {
        let outObject, value, key;
        if (typeof inObject !== "object" || inObject === null) {
          return inObject; // Return the value if inObject is not an object
        }
        outObject = Array.isArray(inObject) ? [] : {};
        for (key in inObject) {
          value = inObject[key];
          outObject[key] = deepCopyFunction(value);
        }
        return outObject;
      };
      const clone = (obj) => Object.assign({}, obj);
      const renameKey = (object, key, newKey) => {
        const clonedObj = clone(object);
        const targetKey = clonedObj[key];
        delete clonedObj[key];
        clonedObj[newKey] = targetKey;
        return clonedObj;
      };
      let params = {};
      let breachSearchTermsForPost = [];
      store.commit("breachResearch/fillBreachSearchPostResults", []);
      const clonedObjTerms = JSON.parse(
        JSON.stringify(th.processedBreachSearchTerms)
      );
      for (let w = 0; w < clonedObjTerms.length; w++) {
        let eachPost = clonedObjTerms[w];
        delete eachPost.id;
        delete eachPost.showWarning;
        delete eachPost.termTotal;
        let epvalue;
        eachPost.types = [];
        if (eachPost.type) {
          for (let b = 0; b < eachPost.type.length; b++) {
            let ep = eachPost.type[b];
            eachPost.types.push(ep.value);
          }
        }
        delete eachPost.type;
        eachPost = renameKey(eachPost, "termData", "terms");
        breachSearchTermsForPost.push(eachPost);
      }

      let deepCopiedArray = deepCopyFunction(breachSearchTermsForPost);
      th.savedBreachSearchTermsForPost = deepCopiedArray;

      breachesSearchPost(breachSearchTermsForPost, {
        sort_by: "email",
        results_per_page: 500,
        page: 1,
        sort_order: "asc",
      })
        .then(function (result) {
          th.timedOut = false;
          if (result.data.data.length == 0) {
            th.showEmptyMsg = true;
            return;
          } else {
            if (
              result.data.data.errorMessage !== undefined &&
              result.data.data.errorMessage !== "undefined"
            ) {
              th.loadCount = false;
              th.errorDialogTwoMsg = result.data.data.errorMessage;
              th.$ga.exception(result.data.data.errorMessage);
              th.errorDialogTwo = true;
              th.showEmptyMsg = true;
              th.showErrorMsg = true;
              return;
            }
            if (
              result.data.data.error !== undefined &&
              result.data.data.error !== "undefined"
            ) {
              th.loadCount = false;
              th.errorDialogTwoMsg = result.data.data.error;
              th.$ga.exception(result.data.data.error);
              th.errorDialogTwo = true;
              th.showEmptyMsg = true;
              th.showErrorMsg = true;
              return;
            }

            store.commit(
              "breachResearch/fillBreachSearchPostResults",
              result.data.data
            );

            if (th.firstCall == true) {
              th.list1 = [
                { value: "email", text: "Email" },
                { value: "password", text: "Password" },
                { value: "username", text: "Username" },
                { value: "ip_address", text: "IP Address" },
                { value: "first_name", text: "First Name" },
                { value: "last_name", text: "Last Name" },
                { value: "breach_slug", text: "Breach" },
              ];
              th.selectedHeaders = th.headers;

              th.firstCall = false;
            }
            setTimeout(() => {
              if (result.data.data.length < 20) {
                th.stopLoadingResults = true;
                th.currentPage = 1;
              }
              th.showDownload = true;
            }, 1000);
          }
        })
        .catch(function (error) {
          th.loadCount = false;
          th.showDownload = true;
          th.showEmptyMsg = false;
          th.showErrorMsg = false;
          th.resultThresholdDialog = true;
          th.timedOut = true;
          console.log(store.state);
          return;
        });
      breachesSearchCountPost(breachSearchTermsForPost, {})
        .then(function (result) {
          console.warn(result);
          if (
            result.data.data.errorMessage !== undefined &&
            result.data.data.errorMessage !== "undefined"
          ) {
            th.breachesSearchCountPostTotal = result.data.data.errorMessage;
            th.$ga.exception(result.data.data.errorMessage);
            th.loadCount = false;
            return;
          }
          if (result.data.data.is_count_capped == false) {
            const formattedResultCount = numFormatComma(result.data.data.count);
            th.breachesSearchCountPostTotal = formattedResultCount + " Results";
          } else if (result.data.data.is_count_capped == true) {
            th.countCapped = true;
            const formattedResultCount = numFormatComma(result.data.data.count);
            th.breachesSearchCountPostTotal =
              formattedResultCount + "+ Results";
          } else {
            const formattedResultCount = numFormatComma(result.data.data.count);
            th.breachesSearchCountPostTotal = formattedResultCount + " Results";
          }
          th.loadCount = false;
        })
        .catch(function (error) {
          console.debug("2", error);
          breachesSearchCountPost(breachSearchTermsForPost, {})
            .then(function (result) {
              console.debug("3", result.data.data);
              if (
                result.data.data.errorMessage !== undefined &&
                result.data.data.errorMessage !== "undefined"
              ) {
                th.breachesSearchCountPostTotal = result.data.data.errorMessage;
                th.$ga.exception(result.data.data.errorMessage);
                th.loadCount = false;
                return;
              }
              if (result.data.data.is_count_capped == false) {
                const formattedResultCount = numFormatComma(
                  result.data.data.count
                );
                th.breachesSearchCountPostTotal =
                  formattedResultCount + " Results";
              } else if (result.data.data.is_count_capped == true) {
                th.countCapped = true;
                const formattedResultCount = numFormatComma(
                  result.data.data.count
                );
                th.breachesSearchCountPostTotal =
                  formattedResultCount + "+ Results";
              }
              th.loadCount = false;
            })
            .catch(function (error) {
              console.debug("4", error);
              th.breachesSearchCountPostTotal = "Count Unavailable";
              th.loadCount = false;
              return;
            });
        });
    },
    log: function (evt) {
      let th = this;

      let table = document
        .getElementById("brMainTable")
        .getElementsByTagName("table")[0];
      for (var i = 0; i < table.rows.length; i++) {
        for (var j = 0; j < table.rows[i].cells.length; j++) {
          if (table.rows[0].cells[j].innerText == "Profile URL") {
            table.rows[i].cells[j].style.overflow = "hidden";
            table.rows[i].cells[j].style.textOverflow = "ellipsis";
            table.rows[i].cells[j].style.maxWidth = "390px";
            table.rows[i].cells[j].title = table.rows[i].cells[j].innerText;
            let tCell = table.rows[i].cells[j];
            if (tCell.innerText.length > 0 && tCell.tagName == "TD") {
              tCell.addEventListener("click", function () {
                let tempInput = document.createElement("input");
                tempInput.value = tCell.innerText;
                document.body.appendChild(tempInput);
                tempInput.select();
                th.showClipboard = true;
                document.execCommand("copy");
                document.body.removeChild(tempInput);
              });
            }
          }
        }
      }
    },
  },
};
